<template>
  <div class="updateAndroid">
    <el-button type="text" @click="$router.go(-1)">&lt;&lt;返回</el-button>
    <!--主体内容-->
    <div class="content">
      <TitleModule :title="title"></TitleModule>
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <div class="disabledAddListWrapper">
          <el-upload
            v-if="!formInline.channelList.downloadUrl"
            class="upload"
            :disabled="!isEdit"
            ref="upload"
            action="/acb/2.0/application/patch/upload"
            :headers="uploadHeader"
            accept="jar"
            name="file"
            :data="{ applicationId: $route.query.applicationId }"
            enctype="multipart/form-data"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-error="handleAvatarError"
          >
            <el-button size="small" type="text" :disabled="!isEdit">
              上传补丁包 <br />(支持jar文件，最大不超过50MB)
            </el-button>
          </el-upload>
          <div
            class="upload uploadText"
            v-show="formInline.channelList.downloadUrl"
          >
            <label>包名：</label>
            <span>{{ formInline.channelList.packageName }}</span>
            <label>大小：</label>
            <span>{{ formInline.channelList.fileSize }}</span>
            <i
              class="el-icon-error"
              @click="removePatch"
              v-show="isEdit === 'add'"
            ></i>
          </div>
        </div>
        <el-form
          label-position="right"
          :disabled="!isEdit"
          label-width="120px"
          :model="formInline"
          style="width: 600px"
          :rules="rules"
          ref="form"
        >
          <h2>高级配置</h2>
          <el-form-item label="应用版本号" prop="versionName">
            <el-select v-model="formInline.versionName" placeholder="请选择">
              <el-option
                v-for="item in versionNameList"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="升级时间" prop="upgradeType">
            <el-radio
              v-model="formInline.upgradeType"
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
              :label="1"
              >立即升级</el-radio
            >
            <el-radio
              v-model="formInline.upgradeType"
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
              :label="2"
              style="margin-right: 20px"
              >定时升级</el-radio
            >
            <el-date-picker
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
              v-show="formInline.upgradeType !== 1"
              :clearable="false"
              :editable="false"
              :picker-options="pickerOptions"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="formInline.upgradeTime"
              type="datetime"
              @change="upgradeTimeSelect"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('searchModule.remarks')" prop="updateDesc">
            <el-input
              type="textarea"
              placeholder="请输入更新说明"
              maxlength="200"
              :autosize="{ minRows: 4, maxRows: 8 }"
              v-model="formInline.updateDesc"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button
            type="primary"
            @click="submitData"
            style="width: 88px"
            v-if="isEdit"
            >{{ $t('button.preservation') }}</el-button
          >
          <el-button type="info" @click="$router.go(-1)" style="width: 88px">{{
            isEdit ? "取消" : "返回"
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// noinspection NpmUsedModulesInstalled
import TitleModule from "@/components/titleModule/titleModule";
import formatDate from "format-datetime";
export default {
  data() {
    return {
      versionNameList: [],
      patchData: {},
      removeIndex: "",
      uploadShow: false,
      title: "添加补丁包",
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      isEdit: "",
      formInline: {
        upgradeStatus: 0,
        applicationName: "",
        productId: "",
        appStoreUrl: "",
        versionName: "",
        updateDesc: "",
        upgradeType: 1,
        upgradeTime: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss"),
        channelList: {
          downloadUrl: "",
          channelName: "",
        },
      },
      rules: {
        versionName: [
          {
            required: true,
            message: "请选择应用版本号",
            trigger: "change",
          },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  methods: {
    //  获取应用版本列表
    getVersionNameList() {
      this.$axios
        .get("/acb/2.0/application/patch/getVersionList", {
          data: {
            applicationId: this.$route.query.applicationId,
          },
        })
        .then((res) => {
          this.versionNameList = [];
          res.value.forEach((v) => {
            this.versionNameList.push({ value: v });
          });
        });
    },
    queryDetail(patchId) {
      this.$axios
        .get("/acb/2.0/application/patch/queryDetail", {
          data: {
            patchId,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            let newDetail = res.value;
            for (let key in newDetail) {
              // eslint-disable-next-line no-prototype-builtins
              if (newDetail.hasOwnProperty(key) && newDetail[key]) {
                this.formInline[key] = newDetail[key];
              }
            }
            this.formInline.channelList.packageName = newDetail.packageName;
            this.formInline.channelList.fileSize = newDetail.fileSize;
            this.formInline.channelList.downloadUrl = newDetail.downloadUrl;
          }
        });
    },
    upgradeTimeSelect(value) {
      let now = new Date().getTime();
      let setTime = new Date(value).getTime();
      if (setTime < now) {
        this.$message.closeAll();
        this.$message.error("选择时间必须大于当前时间");
        return false;
      } else {
        return true;
      }
    },
    channelListVali() {
      let channelListVali = false;
      if (!this.formInline.channelList.downloadUrl) {
        channelListVali = true;
        this.$message.closeAll();
        this.$message.error("请上传补丁包！");
      }
      return channelListVali;
    },
    submitData() {
      if (this.channelListVali()) {
        return false;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (
            this.formInline.upgradeType === 2 &&
            this.formInline.upgradeStatus !== 1 &&
            !this.upgradeTimeSelect(this.formInline.upgradeTime)
          ) { return false; }
          this.$confirm("您确定要保存此操作吗？").then((res) => {
            if (res === "confirm") {
              let url = "/acb/2.0/application/patch/update";
              let data = {
                patchId: this.$route.query.patchId,
                updateDesc: this.formInline.updateDesc,
                upgradeType: this.formInline.upgradeType,
                upgradeTime: this.formInline.upgradeTime,
              };
              if (this.isEdit === "add") {
                url = "/acb/2.0/application/patch/add";
                data = {
                  applicationId: this.applicationId,
                  versionName: this.formInline.versionName,
                  downloadUrl: this.formInline.channelList.downloadUrl,
                  md5Value: this.formInline.channelList.md5Value,
                  packageName: this.formInline.channelList.packageName,
                  fileSize: this.formInline.channelList.fileSize,
                  updateDesc: this.formInline.updateDesc,
                  upgradeType: this.formInline.upgradeType,
                  upgradeTime: this.formInline.upgradeTime,
                };
              }
              this.$axios.post(url, { data }).then((res) => {
                if (res.state === 0) {
                  this.$message.success("操作成功");
                  this.$router.go(-1);
                } else {
                  this.$alert(res.desc);
                }
              });
            }
          });
        }
      });
    },
    beforeAvatarUpload(file) {
      let arr = file.name.split(".");
      let fileName = arr[arr.length - 1];
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (fileName !== "jar") {
        this.$message.error({
          message: "上传文件只能jar 格式!",
          duration: 5000,
        });
        return false;
      } else if (!isLt50M) {
        this.$message.error("上传文件大小不能超过 50MB!");
        return false;
      } else {
        return true;
      }
    },
    handleAvatarError(err) {
      this.$alert(err.message);
    },
    // 移除安装包
    removePatch() {
      this.$axios
        .post("/acb/2.0/application/patch/remove", {
          data: {
            downloadUrl: this.formInline.channelList.downloadUrl,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            this.$message.success("删除成功！");
            this.formInline.channelList = {
              downloadUrl: "",
              channelName: "",
            };
          }
        });
    },
    // 上传文件
    handleAvatarSuccess(response) {
      if (response.state === 0) {
        this.$message({
          message: "上传成功！",
          type: "success",
        });
        this.formInline.channelList = response.value;
      } else if (response.state === 2 || response.state === 5) {
        if (sessionStorage.toLogin === "true") {
          this.$alert("登录失效，请重新登录", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
            showCancelButton: false,
          })
            .then(() => {
              sessionStorage.toLogin = false;
              this.$router.push("/");
            })
            .catch(() => {
              this.$router.push("/");
            });
        }
      } else {
        this.$alert(response.desc);
      }
    },
    // 更新新版时候，查询上一次的详情
    getInfo() {
      this.$axios
        .get("/acb/2.0/application/record/queryLastInfo", {
          data: {
            applicationId: this.$route.query.applicationId,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            let newDetail = res.value;
            for (let key in newDetail) {
              // eslint-disable-next-line no-prototype-builtins
              if (newDetail.hasOwnProperty(key) && newDetail[key]) {
                this.formInline[key] = newDetail[key];
              }
            }
          }
        });
    },
  },
  components: {
    TitleModule,
  },
  created() {
    if (this.$route.query.applicationId) {
      this.applicationId = this.$route.query.applicationId;
      this.isEdit = this.$route.query.isEdit || "";
      if (this.isEdit === "add") {
        this.title = "添加补丁包";
        this.uploadShow = true;
      } else {
        if (this.isEdit) {
          this.title = "更新补丁包";
        } else {
          this.title = "查看补丁包详情";
        }
        this.queryDetail(this.$route.query.patchId);
      }
      // 获取应用版本号列表
      this.getVersionNameList();
    }
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  padding: 20px;
  width: 1100px;
}

.disabledAddListWrapper {
  position: relative;
}

.searchWrapper h2 {
  line-height: 50px;
  font-size: 14px;
}
.searchWrapper .upload {
  width: 80%;
  margin: 0 auto;
  height: 160px;
  border: 1px dashed #20a0ff;
  border-radius: 4px;
}

/*noinspection CssUnusedSymbol*/
.searchWrapper .upload >>> .el-upload {
  display: block;
  width: 100%;
  height: 100%;
}
.searchWrapper .upload button {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  line-height: 1.5;
}
.uploadText {
  line-height: 180px;
  text-align: center;
  position: relative;
}
.uploadText i {
  position: absolute;
  right: -15px;
  top: -15px;
  font-size: 30px;
}
.uploadText i:hover {
  color: #5cb6ff;
}
.uploadText span {
  font-size: 12px;
  margin-right: 10px;
}
.uploadText label {
  font-size: 14px;
}
</style>
